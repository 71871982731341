import React, { useState } from 'react';

function IndividualUserChat({
  localPeerId,
  directMessages,
  selectedUserId,
  usersArray,
  setActiveChatWindow,
  sendDirectMessage,
  teacherView,
  isRoomJoined
}) {
  const [message, setMessage] = useState('');
  const user = usersArray.find((user) => user.socketId === selectedUserId);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message?.trim()) {
      sendDirectMessage(selectedUserId, message, usersArray[0].name);
      setMessage('');
    }
  };

  console.log(selectedUserId, usersArray);

  return (
    <>
      <div className="flex items-center gap-4 mx-4 py-2">
      {teacherView && (
          <button onClick={() => setActiveChatWindow(1)}>
            <span className="sr-only">Back</span>
            <span>&larr;</span>
          </button>
        )}
        {user && (
          <div className="flex items-center gap-2.5">
            <img
              src="/images/navbar/profile.png"
              className="size-8 rounded-full object-cover object-center aspect-square"
              alt={user?.name}
            />
            <h3 className="text-lg font-semibold">{user?.name}</h3>
          </div>
        )}
      </div>
      <div className="h-full overflow-auto px-4 mt-4">
        {directMessages[selectedUserId]?.map((ele, i) => (
          <React.Fragment key={i}>
            {/* <div className="mb-4">
              <div className="bg-blue-500 text-white p-3 rounded-t-lg rounded-br-lg inline-block max-w-xs">
                Hi, how can I help you?
              </div>
            </div>
            <div className="mb-4 text-right">
              <div className="bg-gray-200 text-gray-900 p-3 rounded-t-lg rounded-bl-lg inline-block max-w-xs">
                I have a question about your product.
              </div>
            </div> */}
            <div
              className={`mb-4 ${ele.id === localPeerId ? 'text-right' : 'text-left'}`}
            >
              <div
                className={`${ele.id === localPeerId ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-900'} p-3 rounded-t-lg rounded-bl-lg inline-block max-w-xs`}
              >
                {ele?.msg}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="flex px-2 py-1 mx-4 my-2 border border-gray-300 rounded-lg">
        <form onSubmit={handleSubmit} className="flex w-full">
          <input
            type="text"
            placeholder="Type a message"
            className="w-full py-2 rounded-l-full px-2 focus:outline-none focus:border-transparent"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button className="px-2 mt-2 cursor-pointer ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="31"
              viewBox="0 0 28 31"
              fill="none"
            >
              <path
                d="M27.75 15.1554L0 30.3109L8.25 15.1554L0 0L27.75 15.1554Z"
                fill="#606060"
              />
            </svg>
          </button>
        </form>
      </div>
    </>
  );
}

export default IndividualUserChat;
